<template>
    <div>
        <div class="header">
             <span @click="gouot"></span>
             <span></span>
             <span @click="islogout">退出</span>
        </div>
        <div class="contnet_list">
            <div v-if="!isWechat ">
                <van-field
                    v-model="carInfoType"
                    center
                    clearable
                    :label="resultName"
                    >
                    <template #button v-if="idx!=3" >
                        <!-- <van-button size="small" type="primary" @click="photograph"></van-button> -->
                        <van-uploader :after-read="afterRead" >
                            <van-button size="small"  native-type="button" type="info">拍照识别</van-button>
                        </van-uploader>
                    </template>
                </van-field>
            </div>
            <div v-else>
                <div v-if="idx == 1">
                    <van-field
                        v-model="carInfoType"
                        center
                        clearable
                        :label="resultName"
                    >
                    <template #button >
                         <van-button size="small"  @click="WeChooseImage(1,'45Degrees')" type="info">拍照识别</van-button>
                    </template>
                </van-field>
                </div>
                <div v-if="idx == 2">
                    <van-field
                        v-model="carInfoType"
                        center
                        clearable
                        :label="resultName"
                    >
                        <template #button >
                        
                            <van-button size="small"  @click="WeChooseImage(1,'Vin')" type="info">拍照识别</van-button>
                        </template>
                    </van-field>
                </div>
                <div v-if="idx == 3">
                    <van-field v-model="carInfoType" center clearable :label="resultName"  ></van-field>
                </div>
            </div>
          
            <van-button v-show="typeId==1" type="info" size="large" @click="inquireInfo">查询</van-button>
            <van-button v-show="typeId==2" type="info" size="large" @click="GetinquireInfo">查询</van-button>
            <br>
            <p v-show="inquireInfoObj.carNumberPlate">车牌号：{{inquireInfoObj.carNumberPlate}}</p>
            <p v-show="inquireInfoObj.vin">车架号：{{inquireInfoObj.vin}}</p>
             <van-form @submit="onSubmit">
                <van-field
                    v-if="idx!=3"
                    :readonly="typeId==2"
                    v-model="defined_num"
                    name="自定义进厂编号"
                    label="自定义进厂编号"
                    placeholder="请输入自定义进厂编号"
                   label-width="130"
                />
                <van-field readonly clickable v-model="defined_num_name"  name="picker" label-width="130"  label="自定义车辆类型：" placeholder="请选择车辆类型" @click="CarShowChange" ></van-field>
                <van-popup v-model="CarShow" position="bottom"  :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="TypeList"
                        value-key="defined_type"
                        @confirm="onCarShow"
                        @cancel="CarShow = false"
                    />
                </van-popup>
                <van-field required readonly
                clickable v-model="warehouseName" name="picker" 
                label-width="130"  
                label="选择库房："
                :rules="[{ required: true, message: '请选择库房' }]"
                 placeholder="请选择库房" @click="CarShelfChange" ></van-field>
                <van-popup v-model="CarShelf" position="bottom"  :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="goodsShelf"
                        value-key="name"
                        @confirm="onCarShelf"
                        @cancel="CarShelf = false"
                    />
                </van-popup>
                <van-field required readonly clickable v-model="storageName" name="picker" label-width="130"  label="选择货架：" placeholder="请选择货架"
                :rules="[{ required: true, message: '请选择货架' }]"
                 @click="CarLayerChange" ></van-field>
                <van-popup v-model="CarLayer" position="bottom"  :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="CargoLayer"
                        value-key="name"
                        @confirm="onCarLayer"
                        @cancel="CarLayer = false"
                    />
                </van-popup>
                <van-field required :readonly="typeId==2" name="picker" label-width="130" v-model="cargoLocationText"  label="填写货位：" placeholder="请填写货位" :rules="[{ required: true, message: '请填写货位' }]"></van-field>
                <div style="margin: 16px;" v-show="typeId!=2">
                    <van-button round block type="info" native-type="submit">提交</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
import { is_post } from "../../Http/api.js";
import { deleteCookie } from '@/utils/env'
import { Toast } from 'vant';
import { encipherMent, encryptDecode } from '@/utils/encrypt'
    export default {
        data() {
            return {
                sms:"",
                TypeList:[],
                CarShow:false,
                CarShelf:false,
                CarLayer:false,
                goodsShelf:[],
                CargoLayer:[],
                username:"",
                defined_num:"",//自定义编号
                carInfoType:"",//车牌号
                cargoLocationId:"",//车牌号
                cargoLocationText:"",//车牌号
                warehouseName:"",//库位
                warehouseId:"",//库位
                storageName:"",//库位
                storageId:"",//库位
                defined_num_name:"",
                defined_num_Id:"",
                idx:'',
                inquireInfoObj:{},
                fileList1:[],
                fileList2:[],
                isData:{},
                vindata:"",
                imageUrl:"",
                VinUrl:"",
                 wximagelocalids:[],//判断上传张数
                 toast:null,
            }
        },
       
        computed: {
            resultName() {
                let a=''
                switch (this.idx) {
                    case 1:
                        a = '车牌号'
                        break;
                    case 2:
                        a = '车架号'
                        break;
                    case 3:
                         a = '进厂编号'
                        break;
                
                    default:
                        break;
                }
                return a
            },
            typeId(){
                return this.$route.query.id
            }
        },
        mounted () {
           
            this.wcConfig()
        },
        watch:{
            isData:{
                handler(a,v){
                    console.log(a,v)
                    this.carInfoType = a.carNumberPlate
                },
                deep:true
            },
            vindata(e,v){
                this.carInfoType = e
            },
             wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            }
        },
        methods: {
            //查看的时候不能打开

            CarShelfChange(){
                if(this.typeId == 1){
                    this.CarShelf = true
                }
            },
            //查看的时候不能打开

            CarLayerChange(){
                if(this.typeId == 1){
                    this.CarLayer = true
                }
            },
            //查看的时候不能打开
            CarShowChange(){
                if(this.typeId == 1){
                    this.CarShow = true
                }
            },
            //查询
            async GetinquireInfo(){
                try{
                    let obj = {}
                    if(this.idx == 1){
                    obj = {carNumberPlate:this.carInfoType,}
                    }else if(this.idx == 2){
                        obj = {vin:this.carInfoType}
                    }else if(this.idx == 3){
                        obj = {defined_num:this.carInfoType}
                    }
                    let data = encipherMent(JSON.stringify(obj))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post('index.php/index/end_of_life_vehicles/endOfLifeVehiclesLibraryStorage',param).then(res=>res)
               
                    this.inquireInfoObj = res.data
                    this.defined_num = this.inquireInfoObj.defined_num
                    this.warehouseName = this.inquireInfoObj.cargoLocationIdText
                    this.storageName = this.inquireInfoObj.cargoLocationRackIdText
                    this.cargoLocationText = this.inquireInfoObj.cargoLocationText
                    this.defined_num_name = this.inquireInfoObj.defined_type
                }
                catch{
                    this.inquireInfoObj = {}
                    this.defined_num = ''
                    this.warehouseName = ''
                    this.storageName = ''
                    this.cargoLocationText = ''
                    this.defined_num_name = ''
                }
                
            },
            //
            gouot() {
                this.$emit('closePopup')
            },
            //提交
            async onSubmit(){
                try{
                    let obj = {
                        cargoLocationId:this.warehouseId,
                        cargoLocationRackId:this.storageId,
                        cargoLocationText:this.cargoLocationText,
                        defined_num:this.defined_num,
                        carNumberPlate:this.inquireInfoObj.carNumberPlate,
                        vin:this.inquireInfoObj.vin,
                        end_of_lifevehicles_id:this.inquireInfoObj.id,
                        defined_num_id:this.defined_num_Id
                    }
                    let data = encipherMent(JSON.stringify(obj))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post('index.php/index/end_of_life_vehicles/putIntoStorageEndOfLifeVehicles',param).then(res=>res)
                    this.$emit('closePopup')
                  
                }
                catch{

                }
                
            },
            onCarShow(e){
               
                this.defined_num_name = e.defined_type
                this.defined_num_Id = e.id
                this.CarShow = false
            },
            //选择第二次库位
            onCarLayer(e){
                this.storageName = e.name
                this.storageId = e.id
                this.CarLayer = false
            },
            //上传失败
            afterRead(file){
                 var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                let params = new FormData(); //创建form对象
                    params.append("file", file.file);
                   this.$http.post("/index.php/index/upload/index", params)
                    .then((res) => {
                    if (res.data.code == 0) {
                         toast.clear()//清除加载效果
                        //判断行驶证正面还是反面
                        if(this.idx == 1) {
                            let url = 'index.php/vehicles_upload/vehicleCarNumberPlate'
                            this.recognition(url,{path:res.data.data},1)
                        }else if(this.idx == 2){ 
                            let url = '/index.php/index/bid_management.bid_order/apiBaiDu'
                            this.recognition(url,{url:res.data.data},'vin')
                        }
                        file.status = "done";
                    } else if(res.data.code == -1){
                        deleteCookie('token')
                        
                        this.$toast.fail(res.data.msg)
                        this.$router.push('/')
                    }else{
                        // file.status = "failed";
                        // file.message = "上传失败";
                        if(itm == 1){
                           
                            this.fileList1 = []
                        }else if(itm == 2){
                           
                            this.fileList2 = []
                        }
                         toast.clear()//清除加载效果
                        this.$toast.fail(res.data.msg)
                    }
                    }).catch((err)=>{
                        // file.status = "failed";
                        // file.message = "上传失败";

                        this.frontUrl = ''
                        this.reverseUrl = ''
                        this.fileList1=[],//行驶证正面照片
                            this.fileList2=[],//反面照片

                         toast.clear()//清除加载效果
                        this.$toast.fail('网络开小差了，请稍后重试！')
                    })
            },
            //车辆c查询
            async inquireInfo(){
                try{
                    let obj = {}
                    if(this.idx == 1){
                    obj = {carNumberPlate:this.carInfoType,}
                    }else if(this.idx == 2){
                        obj = {vin:this.carInfoType}
                    }else if(this.idx == 3){
                        obj = {defined_num:this.carInfoType}
                    }
                    
                    let data = encipherMent(JSON.stringify(obj))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post('index.php/index/end_of_life_vehicles/endOfLifeVehiclesLibrary',param).then(res=>res)
                    this.inquireInfoObj = res.data
                   
                }
                catch{

                }
                
            },
            //清空
            kong(){
                this.defined_num = ''//自定义编号
                this.carInfoType = ''//车牌号
                this.cargoLocationId = ''//车牌号
                this.cargoLocationText = ''//车牌号
                this.warehouseName = ''//库位
                this.warehouseId = ''//库位
                this.storageName = ''//库位
                this.inquireInfoObj={}
                this.storageId = ''//库位
            },
            //获取当前点击
            idxs(i){
                 this.idx = i
            },
            //库位选择获取二层
            onCarShelf(e){
                console.log(e)
                this.warehouseName = e.name
                this.warehouseId = e.id
                
                this.$http.post('/index.php/index/cargo_Location/getList',{pid:e.id}).then(res=>{
					
					if(res.data.code == 0) {
						this.CargoLayer = res.data.data
					}else if(res.data.code == '-1'){deleteCookie('token')
						this.$message({showClose: true,duration:3000,message: res.data.msg,type: 'error'});this.$router.push('/')
					}else{
						this.$message({showClose: true,duration:3000,message: res.data.msg,type: 'error'});
					}
				});
                this.CarShelf = false
            },
            //获取第一层库位
            getInventory(){
                this.$http.post('/index.php/index/cargo_Location/getList',{pid:'0'}).then(res=>{
                    if(res.data.code == 0) {
                        this.goodsShelf = res.data.data
                    }else if(res.data.code == '-1'){deleteCookie('token')
                        this.$message({showClose: true,duration:3000,message:res.data.msg,type: 'error'});this.$router.push('/')
                    }else{
                        this.$message({showClose: true,duration:3000,message: res.data.msg,type: 'error'});
                    }
                });
                this.$http.post('/index.php/defined_num/_list').then(res=>{
                    if(res.data.code == 0) {
                        this.TypeList = res.data.data
                    }else if(res.data.code == '-1'){deleteCookie('token')
                        this.$message({showClose: true,duration:3000,message:res.data.msg,type: 'error'});this.$router.push('/')
                    }else{
                        this.$message({showClose: true,duration:3000,message: res.data.msg,type: 'error'});
                    }
                });
            },
          
        },
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
        display: inline-block;
    }
    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}
.contnet_list{
    margin-top: 50px;
    padding: 0 10px;
}
.uploaderImg{
    display: inline-block;
    width: 100px ;
    height: 100px ;
    border: 1px solid #eee;
    position: relative;
    .iconz{
        position: absolute;
        top: 42%;
        left: 38%;
    }
}
.vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>
<template>
    <div>
        <div class="header">
             <span @click="gouot"></span>
             <span>{{title}}</span>
             <span @click="islogout">退出</span>
        </div>
        <div class="order">
            <van-button type="info" @click="QuetyChange(1)" size="large">按车牌号查询</van-button>
            <van-button type="info" @click="QuetyChange(2)" size="large">按车架号查询</van-button>
            <van-button type="info" @click="QuetyChange(3)" size="large" v-if="$route.query.id == 2">按自定义进厂编号查询</van-button>
            <!-- <van-button type="info" @click="QuetyChange(3)" size="large" >按自定义进厂编号查询</van-button> -->
        </div>
        <van-popup
            v-model="isDiscernShow"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{
                height: '100%',
            }"
        >
        <InventoryList ref="Inventory" @closePopup="closePopup"/>
    </van-popup>
    </div>
</template>

<script>
import InventoryList from './InventoryList'
    export default {
        components: {
            InventoryList,
        },
        data() {
            return {
               isDiscernShow:false,
            }
        },
        computed: {
            title() {
                return this.$route.query.id == 1 ? '销户车入库':'销户车库位查询'
            }
        },
        methods: {
            closePopup(){
                this.isDiscernShow = false
            },
            gouot() {
                this.$router.push('/guide')
            },
            QuetyChange(i){
                this.isDiscernShow = true
                this.$nextTick(()=>{
                    this.$refs.Inventory.getInventory()
                    this.$refs.Inventory.kong()
                    this.$refs.Inventory.idxs(i)
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
        display: inline-block;
    }
    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}
.order{
    margin-top: 50px;
    padding: 0 10px;
    .van-button{
        margin: 20px 0;
    }
}
</style>